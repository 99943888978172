/* Estilos personalizados */
body {
  font-family: Arial, sans-serif;
  font-size: 14px !important;
}

h1 {
  font-family: "Roboto", sans-serif; /* Altera a fonte do h1 */
}
.btns .btn-success {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.sidebar {
  background-color: #005f87; /* Azul escuro */
  padding: 10px 0;
}

.sidebar a {
  color: white;
  text-decoration: none;
  padding: 10px 15px;
  display: inline-block;
  font-weight: bold;
}

.sidebar a:hover {
  background-color: #007bb5; /* Azul */
}

.content {
  padding: 20px;
  margin-left: 15px; /* Margem esquerda */
  margin-right: 15px; /* Margem direita */
}

.navbar {
  background-color: #3a3d3f; /* Azul */
}

.navbar .navbar-brand {
  font-weight: bold;
  color: white;
}

.navbar .nav-link {
  color: white;
}

.logo {
  max-width: 200px; /* Ajuste o tamanho conforme necessário */
  height: auto;
  margin-right: 10px; /* Espaço entre a logo e o nome */
}

.container-custom {
  margin-left: 25px; /* Margem esquerda */
  margin-right: 25px; /* Margem direita */
}

.espaco {
  margin-left: 25px; /* Margem esquerda */
  margin-right: 25px; /* Margem direita */
}
