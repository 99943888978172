.area-login {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("https://rbhidraulicos.com.br/wp-content/uploads/2024/01/fundo-branco-scaled.jpg");
  background-size: cover; /* Faz a imagem cobrir toda a área */
  background-repeat: no-repeat; /* Evita a repetição da imagem */
  background-position: center; /* Centraliza a imagem */
  height: 100vh;
}
.login-container {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 400px;
  width: 100%;
}
.logo-l {
  position: absolute;
  left: 0;
  top: 0;
}

.logo-l img {
  width: 250px;
  border: none;
  border-radius: 0;
  margin: 30px;
  box-shadow: none;
}
